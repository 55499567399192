
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "verify-login",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const isSuccess = ref(false);
    if (
      route.query &&
      route.query.token &&
      route.query.nonce &&
      route.query.source
    ) {
      store
        .dispatch(Actions.VALIDATE_MY_TENS_CRED, {
          encrypt: route.query.token,
          nonce: route.query.nonce,
          source: route.query.source,
        })
        .then(() => {
          isSuccess.value = true;
          return store.dispatch(Actions.VERIFY_AUTH);
        })
        .then(() => {
          router.push({ name: "dashboard-index" });
        })
        .catch(() => {
          store.commit(Mutations.SET_INTEGRATOR_LOADING, false);
        });
    }
    const errors = computed(() => store.getters.getErrors);
    return {
      store,
      errors,
      isSuccess,
    };
  },
});
